<template>
  <Content>
    <div class="content-ask-name" v-if="!enterName">
      <span class="title-ask-name">WHAT’S YOUR NAME?</span>
      <BaseInput
        class="form-group"
        id="login_email"
        :placeholder="'Name'"
        v-model="userName"
        :hasError="$v.userName.$error"
        @blur="blur"
        @focus="focus"
        ref="baseInput"
      />
      <TermsAndConditionsInfo
        v-if="!getProfile.token"
        :name-button="'NEXT'"
      />
      <div class="space-ask-name"></div>
    </div>
    <LetsFindStrength v-else/>
    <ActionButton
      v-if="!enterName"
      :text="'NEXT'"
      :disabled-button="($v.userName.$invalid)"
      @click-button="saveName"/>
  </Content>
</template>

<script>

import { validationMixin } from 'vuelidate';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import LetsFindStrength from '@innerworks_ds/innerworks-storybook-main/src/stories/LetsFindStrength/LetsFindStrength.vue';
import TermsAndConditionsInfo from '@innerworks_ds/innerworks-storybook-main/src/stories/TermsAndConditionsInfo/TermsAndConditionsInfo.vue';
import userBehave from '@constants/userBehave';
import BaseInput from '@innerworks_ds/innerworks-storybook-main/src/stories/CustomInputs/BaseInput/BaseInput.vue';
import { mapGetters } from 'vuex';

const { required, minLength } = require('vuelidate/lib/validators');

const { detect } = require('detect-browser');

const browser = detect();

const checkUserName = (userName) => {
  const re = /^[a-z ,.'-]+$/i;
  return re.test(String(userName));
};

export default {
  name: 'AskName',
  components: {
    ActionButton, LetsFindStrength, BaseInput, TermsAndConditionsInfo,
  },
  mounted() {
    this.$store.dispatch('showComponent/showHeaderMain');
    if (browser && ((browser.os !== 'iOS') && (browser.os !== 'Mac OS'))) {
      this.$refs.baseInput.focusInput();
      document.querySelector('.form__input').click();
    }

    if (browser && ((browser.os === 'iOS') || (browser.os === 'Mac OS'))) {
      this.manipulateButton();
    }
  },
  data: () => ({
    userName: '',
    enterName: false,
    redirectTimer: null,
    showWindowModal: false,
  }),
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
    }),
  },
  watch: {
    userName() {
      this.$v.$touch();
    },
  },
  mixins: [validationMixin],
  validations: {
    userName: {
      required,
      minLength: minLength(1),
      mustBeCool: checkUserName,
      $lazy: false,
      $autoDirty: true,
    },
  },
  beforeDestroy() {
    clearTimeout(this.redirectTimer);
  },
  methods: {
    manipulateButton() {
      const button = document.querySelector('.button-custom');
      const input = document.getElementById('login_email');
      const { height } = window.visualViewport;
      const viewport = window.visualViewport;

      window.addEventListener('scroll', () => {
        input.blur();
        window.scrollTo(0, 0);
      });
      // eslint-disable-next-line no-use-before-define
      window.visualViewport.addEventListener('resize', resizeHandler);

      function resizeHandler() {
        button.style.bottom = `${height - viewport.height + 10}px`;
        window.scrollTo(0, 0);
      }
    },
    focus() {
      if (browser && (browser.os === 'iOS')) {
        const constentAskName = document.querySelector('.content-ask-name');
        constentAskName.style.top = '16%';
      }
    },
    blur() {
      if (browser && (browser.os === 'iOS')) {
        const constentAskName = document.querySelector('.content-ask-name');
        constentAskName.style.top = '30%';
      }
    },
    saveName() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.enterName = true;
        this.$api.userBehaveSite.saveSiteEvent(userBehave.askUserName);
        if (this.getProfile.token) {
          this.$api.user.saveUserName(this.userName).then(() => {
            this.$store.dispatch('auth/setUserName', this.userName);
          });
        } else {
          this.$store.dispatch('auth/setUserName', this.userName);
        }
        this.redirectTimer = setTimeout(() => {
          this.$router.push('/questionnaire');
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    height: 100%;
    position: relative;
    font-family: $newDefaultFont;
    min-height: 260px;
  }
  .space-ask-name {
    height: 75px;
  }
  .title-ask-name {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: rgba(50, 9, 89, 0.8);
    margin-bottom: 25px;
  }
  .button-custom {
    position: fixed;
  }
  @media (max-height: 320px) {
    .button-custom {
      position: absolute;
    }
  }
  .content-ask-name {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-left: 30px;
    padding-right: 30px;
    position: absolute;
    top: 30%;
    transform: translate(0, -50%);
    margin-top: 30px;
    width: 100%;
    margin-bottom: 50px;
  }
</style>
